import React from "react"

import { footer } from "./footer.module.css"
import Container from "./bootstrap/container"
import BandsDiamond from "./bands-diamond"
import BandsRectangle from "./bands-rectangle"
import GovNewsDirectIcon from "./govnewsdirect-icon"
import LinkedinIcon from "./linkedin-icon"
import TwitterIcon from "./twitter-icon"
import Row from "./bootstrap/row"
import PartnerCard from "./partner-card"

import GPALogoImage from "./government-property-agency-logo-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import ImgGovNewsDirectLogo from "./img-govnewsdirect-logo"

const Footer = () => (
  <footer className={footer} style={{
    position: `relative`,
    boxShadow: `0 -10px 10px -10px rgba(0, 0, 0, 0.3)`
  }}>
    <BandsDiamond type="fine" size="50px" style={{
      position: `absolute`,
      top: `-25px`,
      left: `50%`,
      marginLeft: `-25px`
    }} />
    <BandsRectangle style={{
      position: `absolute`,
      top: `-13px`,
      right: `0`,
      width: `80px`,
      height: `26px`
    }} />
    <Container style={{ paddingTop: `5rem`, paddingBottom: `3rem` }}>
      <Container>
        <Row>
          <div className="col-lg m-3 py-4" style={{
            borderTop: `1px solid #fff`,
            borderBottom: `1px solid #fff`
          }}>
            <Container>
              <Row>
                <div className="col">
                  <h5>Address</h5>
                  <address style={{margin: `0`}}>
                    GovNewsDirect<br />
                    PROFOLK, Bank Chambers<br />
                    St. Petersgate<br />
                    Stockport<br />
                    SK1 1AR
                  </address>
                </div>
                <div className="col">
                  <h5>Email</h5>
                  <p><OutboundLink href="mailto:enquiries@govnewsdirect.com">enquiries@govnewsdirect.com</OutboundLink></p>

                  <h5>Phone</h5>
                  <p><a href="tel:+441616973438">0161 697 3438</a></p>
                </div>
              </Row>
            </Container>
          </div>
          <div className="col-lg m-3 py-4" style={{
            borderTop: `1px solid #fff`,
            borderBottom: `1px solid #fff`
          }}>
            <Row>
              <div className="col px-1">
                {/* <PartnerCard title="Official Partner">
                  <SmarterWorkingLogoImage />
                </PartnerCard> */}
              </div>
              <div className="col px-1">
                <PartnerCard title="Official Partner">
                  <GPALogoImage/>
                </PartnerCard>
              </div>
              <div className="col px-1">
                <PartnerCard title="Offical Partner">
                  <div style={{
                    maxWidth: `166px`,
                    maxHeight: `95px`,
                    padding: `1.8rem 1.5rem`
                  }}>
                    <ImgGovNewsDirectLogo />
                  </div>
                </PartnerCard>
              </div>
            </Row>
          </div>
        </Row>
      </Container>

      <Container>
        <div className="d-flex justify-content-between" style={{
          marginLeft: `1rem`
        }}>
          <div className="d-flex justify-content-left">
            <OutboundLink href="https://www.govnewsdirect.com" target="_blank" rel="noopener noreferrer"><GovNewsDirectIcon style={{ width: `30px`, margin: `10px`, color: `#fff` }} /></OutboundLink>
            <OutboundLink href="https://linkedin.com/company/govnewsdirect" target="_blank" rel="noopener noreferrer"><LinkedinIcon style={{ margin: `10px`, color: `#fff` }} /></OutboundLink>
            <OutboundLink href="https://www.twitter.com/GovNewsDirect" target="_blank" rel="noopener noreferrer"><TwitterIcon style={{ margin: `10px`, color: `#fff` }} /></OutboundLink>
          </div>
          <div style={{ padding: `.3rem 0`}}>
            <OutboundLink href="https://www.govnewsdirect.com/privacy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</OutboundLink>
          </div>
        </div>
      </Container>
    </Container>
  </footer>
)

export default Footer
